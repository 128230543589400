import React from 'react'

const BePhio = () => (
	<div className="App-buy">
        <a
          className="ui massive inverted button"
          href="https://www.facebook.com/pg/taibunexpo/events/?ref=page_internal"
          target="_blank"
          rel="noopener noreferrer"
        >
          來去買票！</a>
      </div>
)

export default BePhio